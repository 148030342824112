import logoVistoria from '../img/logo-vistoria-central.png'

function NavBar() {

  return (
    <div className="navbar-floating">
      <div className="navbar-container">

        <div>
          <img src={logoVistoria} width={'100px'} alt='Vistoria central logo' />
          <span className='spanNavBar'>Vistoria Central</span>
        </div>

        <div className="navbar-buttons-container">

          <a href='https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central%20'>
            <button className="whatsapp">
              <span>Simule em nosso WhatsApp!</span>
              (11) 2866-7638
            </button>
          </a>
          
        </div>
      </div>
    </div>
  );
}

export default NavBar;