import { useEffect, useState } from 'react';
import { ChatBox } from '../component/chat';
import HeaderSlider from '../component/header-slider';
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, } from 'mdb-react-ui-kit';
import '../index.css';

import { FaThumbsUp, FaUserShield, FaBus, FaStar, FaCarSide, FaMoneyBill, FaMedal } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { FiCheckCircle } from "react-icons/fi";
import { BiSolidCarGarage, BiMessageSquareDetail } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

import { useResponsiveMode } from '../controller/utils';
import NavBar from '../component/navbar';
import DetranLogo from '../img/detranLogo.png';
import InitialBkg from '../img/initial-bkg.png';
import BkgBike from '../img/bkg-hero1.png';
import BkgCar from '../img/bkg-hero2.png';
import BkgTruck from '../img/bkg-hero3.png';
import { FaBullseye, FaCalendarCheck, FaCircleInfo, FaFileLines, FaHandshake, FaMagnifyingGlass } from 'react-icons/fa6';


function Index() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const goToElement = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView();
    };

    const slides = [
        {
            bg: InitialBkg, title: `Vistoria Central: 
Sua Escolha para 
Inspeções Precisas 
e Confiáveis`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgBike, title: `Antes de Comprar,
Previna-se: 
Agende sua 
Vistoria hoje!`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgCar, title: `Vistoria Central: 
a escolha segura para 
transações veiculares 
sem surpresas.`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgTruck, title: `Inspeções 
Profissionais:
Confie na 
Experiência da 
Vistoria Central`, cta_text: '', cta_url: '', cta_url_class: ''
        },
    ];


    const isMobile = useResponsiveMode();
    const [view, setView] = useState('home');
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (icon) => {
        setActiveIcon(icon === activeIcon ? null : icon);
    };

    return (
        <>
            {view === 'home' ?
                <div>
                    <NavBar />
                    <MDBContainer id='home' fluid className="headerContainer">
                        <HeaderSlider slides={slides} id='slider' />
                        {/* {!isMobile && (<div id='ia-assist' className='chatbox-container'> <ChatBox setView={setView} /> </div>)} */}

                        <div className='btnSlideDiv'>
                            <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='btnSlide'>Realizar Orçamento</button>
                        </div>



                    </MDBContainer>
                    <MDBContainer fluid className='bg-default'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={8} className='container-about-section'>
                                <p>Na <span>Vistoria Central Santo André</span>, credenciada pelo <span>DETRAN</span>, sua segurança é nossa prioridade. Com inspeções minuciosas e suporte completo, garantimos que você compre e venda veículos com total confiança e tranquilidade. <span>Vistoria Central</span>: a escolha segura para transações veiculares sem surpresas.</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <div className='circles-holder'>
                            <MDBRow style={{ textAlign: 'center' }} className='justify-content-start align-items-start'>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'inspecao' ? 'expanded' : ''}`} onClick={() => handleIconClick('inspecao')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaMagnifyingGlass size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>INSPEÇÃO</h4>
                                        <div className='content'>
                                            <p>Inspeção veicular detalhada para sua segurança. Vistoria Central garante conformidade e tranquilidade na estrada. Agende sua inspeção hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'relatorio' ? 'expanded' : ''}`} onClick={() => handleIconClick('relatorio')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaFileLines size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>RELATÓRIO</h4>
                                        <div className='content'>
                                            <p>Laudos veiculares confiáveis e completos. Vistoria Central garante transparência e precisão. Obtenha o seu laudo agora!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'precisao' ? 'expanded' : ''}`} onClick={() => handleIconClick('precisao')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaBullseye size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>PRECISÃO</h4>
                                        <div className='content'>
                                            <p>Laudos precisos e detalhados para sua confiança. Vistoria Central assegura exatidão em cada detalhe. Solicite seu laudo agora!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'seguranca' ? 'expanded' : ''}`} onClick={() => handleIconClick('seguranca')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <FaUserShield size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>SEGURANÇA</h4>
                                        <div className='content'>
                                            <p>Confiança e segurança na compra e venda do seu veículo. Vistoria Central oferece laudos e inspeções completas. Garanta uma transação segura hoje mesmo!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'eficiencia' ? 'expanded' : ''}`} onClick={() => handleIconClick('eficiencia')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <BiSolidCarGarage size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>EFICIÊNCIA</h4>
                                        <div className='content'>
                                            <p>Serviço rápido e eficiente para você. Vistoria Central cuida do seu veículo com agilidade. Experimente nossa eficiência hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol>
                                    <div className={`icon-container ${activeIcon === 'qualidade' ? 'expanded' : ''}`} onClick={() => handleIconClick('qualidade')}>
                                        <span className='icon-span mt-3 mb-3'>
                                            <GiCarWheel size={'2.5rem'} color='#f5f5f5' />
                                        </span>
                                        <h4>QUALIDADE</h4>
                                        <div className='content'>
                                            <p>Qualidade que você pode confiar. Vistoria Central oferece serviços de alta qualidade para seu veículo. Garanta a melhor proteção hoje!</p>
                                            <button onClick={() => window.open('https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central', '_blank')}>
                                                Faça uma cotação
                                            </button>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </MDBContainer>
                    <MDBContainer id='vantagens' fluid className='vantagens-container'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <h1 className='vantagens-title'>
                                    <span>Vantagens</span> da <span>Vistoria Central</span>
                                </h1>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='cardsHolder justify-content-center align-items-center'>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaMedal style={{ width: '100%', height: '100%', padding: '10px', color: '#1F824A' }} /></div>
                                        <h3>
                                            Vistoria Veicular Completa:
                                        </h3>
                                        <p>
                                            Nossa equipe realiza inspeções detalhadas para identificar quaisquer
                                            problemas potenciais, proporcionando tranquilidade aos nossos clientes.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaCalendarCheck style={{ width: '100%', height: '100%', padding: '10px', color: '#1F824A' }} /></div>
                                        <h3>
                                            Agendamento Flexível:
                                        </h3>
                                        <p>
                                            Oferecemos agendamento flexível para se adequar à sua agenda ocupada,
                                            garantindo conveniência máxima.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaThumbsUp style={{ width: '100%', height: '100%', padding: '10px', color: '#1F824A' }} /></div>
                                        <h3>
                                            Relatórios Detalhados:
                                        </h3>
                                        <p>
                                            Fornecemos relatórios completos e detalhados após cada
                                            inspeção, para que você tenha uma visão
                                            clara do estado do seu veículo.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FaHandshake style={{ width: '100%', height: '100%', padding: '10px', color: '#1F824A' }} /></div>
                                        <h3>
                                            Atendimento Profissional:
                                        </h3>
                                        <p>
                                            Nossos profissionais altamente qualificados garantem um serviço
                                            de alta qualidade em todas as inspeções, priorizando a segurança
                                            e a satisfação do cliente.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='6'>
                                <MDBCard className='cardsProtec'>
                                    <MDBCardBody>
                                        <div className='icon-services-container'><FiCheckCircle style={{ width: '100%', height: '100%', padding: '10px', color: '#1F824A' }} /></div>
                                        <h3>
                                            Sem Burocracia:
                                        </h3>
                                        <p>
                                            Oferecemos um processo simples e sem complicações, para que você possa
                                            obter uma inspeção de qualidade rapidamente e sem preocupações.
                                        </p>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md='7' className='justify-content-center align-items-center py-4 d-flex'>
                                <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='contactBtn vantagens'>Solicitar Revisão</button>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer>
                        <MDBRow className='placas-mercosul justify-content-center align-items-center'>
                            <MDBCol md={6}>
                                <img src={DetranLogo} alt='Placas Mercosul' id='img_reclame_aqui' />
                            </MDBCol>
                            <MDBCol md={5}>
                                <h4>Credenciada pelo Detran</h4>
                                <p>Na Vistoria Central, temos o orgulho de oferecer serviços de inspeção
                                    veicular credenciados pelo Detran. Como parceiros confiáveis,
                                    garantimos que todas as inspeções sejam realizadas de acordo
                                    com os mais altos padrões de qualidade e segurança estabelecidos
                                    pelo Departamento Estadual de Trânsito. Conte conosco para uma
                                    avaliação precisa e confiável do seu veículo.</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer fluid className='contato-container' id='contato'>
                        <MDBRow className='justify-content-center align-items-center'>
                            <MDBCol md={4} className='text-center contact-col'>
                                <div className='contact-container'>
                                    <span className='contact-icon'><BiMessageSquareDetail /></span>
                                    <h4>Entre em contato agora mesmo!</h4>

                                    <a href='https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central%20'>
                                        <button className="contactBtn whatsapp">
                                            <span>Faça uma vistoria!</span>
                                            (11) 2866-7638
                                        </button>
                                    </a>
                                    {/* <button id='btn-cta-cotacao' onClick={() => goToElement('contato')} className='contactBtn'>Clique aqui e faça uma Revisao!</button> */}
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>

                    <Footer />
                    <a id='whatsappFloat' href="https://wa.me/551128667638?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Vistoria%20Central%20" rel="noreferrer" class="whatsapp-float hover-shadow" target="_blank">
                        <FaWhatsapp />
                    </a>
                    {/* {isMobile && (
                        <span id='upflow-float' onClick={() => setView('chat')} rel="noreferrer" class="upflow-float hover-shadow" target="_blank">
                            <FaCircleInfo size={'3rem'} color='#1F824A' />
                        </span>)
                    } */}
                </div>
                :
                <div>
                    <ChatBox setView={setView} />
                </div>
            }
        </>
    )
}

export default Index;